import { ref, watch, computed } from '@vue/composition-api'
import { getRoles } from '@api'
import { useUtils } from '@core/libs/i18n'
import usePermissions from '@core/utils/usePermissions'
import useFilters from '@core/utils/useFilters'

// import store from '@/store'

export default function useRolesList() {
  const roleListTable = ref([])
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()
  const tableColumns = computed(() => [
    { text: t('roles.name').toUpperCase(), value: 'name', show: true },
    { text: t('roles.description').toUpperCase(), value: 'description', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(2, 4, 5),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQuery = ref('')
  const totalRoleListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const roleTotalLocal = ref([])

  // fetch data
  const fetchRoles = async () => {
    const responseRole = await getRoles()
    const filteredData = responseRole.data.filter(
      role =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        role.name.toLowerCase().includes(searchQuery.value) || role.description.toLowerCase().includes(searchQuery.value),
    )

    roleListTable.value = filteredData
    totalRoleListTable.value = filteredData.length
    roleTotalLocal.value = null
    loading.value = false
  }

  watch([searchQuery, options], () => {
    loading.value = true
    updateFilter('views-role-list', 'search', searchQuery.value)
    fetchRoles()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRoleStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveRoleStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    roleListTable,
    computedTableColumns,
    searchQuery,
    totalRoleListTable,
    loading,
    options,
    roleTotalLocal,

    fetchRoles,
    resolveRoleStatusVariant,
    resolveRoleStatusText,
    hasPermission,
    getFilterByModule,
  }
}
